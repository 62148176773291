import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Nie stworzyliśmy takiej strony</h1>
      <p>Napisz do nas, może warto żeby była.</p>
    </div>
  </Layout>
)

export default NotFoundPage
